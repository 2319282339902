import React from "react"
import Page from "../components/Page"
import { Link, navigate } from "gatsby"
import {
  contactPage,
  contactWrapper,
  title,
  button,
  links,
} from "../styles/404.module.css"
import Layout from "../components/Layout"
import { useTranslation } from "react-i18next"

const NotFound = () => {
  const [t] = useTranslation()
  const navigateBack = e => {
    e.preventDefault()
    navigate(-1)
  }
  return (
    <Page>
      <Layout>
        <div className={contactPage}>
          <div className={contactWrapper}>
            <h1 className={title}>{t("Not Found")}</h1>
            <p>
              {t("Back To")}{" "}
              <button onClick={navigateBack} class={button}>
                {t("Last Page")}
              </button>
            </p>

            <p>{t("Different Page")}</p>
            <ul className={links}>
              <li key={1}>
                <Link to="/">{t("Main Page")}</Link>
              </li>
              <li key={2}>
                <Link to="/technology">{t("Technology")}</Link>
              </li>
              <li key={3}>
                <Link to="/about">{t("About us")}</Link>
              </li>
              <li key={4}>
                <Link to="/joinUs">{t("Participate")}</Link>
              </li>
              <li key={5}>
                <Link to="/contact">{t("Contact")}</Link>
              </li>
            </ul>
          </div>
        </div>
      </Layout>
    </Page>
  )
}

export default NotFound
